import React from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'

const ContactPageTemplate = (props) => {
  const { title, subtitle, adult, adultinfo, coordinator, coordinatorinfo, meta_title, meta_description } = props

  return (
    <div>
      <Helmet>
        <title>{meta_title}</title>
        <meta name='description' content={meta_description} />
      </Helmet>
      <section className='hero has-background is-medium'>
        <img className='hero-background' src='/img/contactusheader.jpg' />
        <div className='hero-body'>
          <div className='container'>
            <div className='columns'>
              <div className='column is-10 is-offset-1'>
                <div className='section'>
                  <h1 className='title is-1'>
                    {title}
                  </h1>
                  <h2 className='subtitle'>
                    {subtitle}
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='section'>
        {/* <div className='container'>
          <ContactForm />
        </div> */}
        <div className='tile columns is-centered'>
          <div className='tile is-vertical is-5.8 m-6'>
            <div className='notification is-primary'>
              <p className='title'>{adult}</p>
            </div>
            <p className='box mt-0 has-text-left'>
              <ul>
                {adultinfo.map(item => (
                  <li key={item}>
                    {item}
                  </li>
                ))}
              </ul>
            </p>
          </div>
          <div className='tile is-vertical is-5.8 m-6'>
            <article className='tile is-child notification is-primary'>
              <p className='title'>{coordinator}</p>
            </article>
            <p className='box mt-0 has-text-left'>
              <ul>
                {coordinatorinfo.map(item => (
                  <li key={item}>
                    {item}
                  </li>
                ))}
              </ul>
            </p>
          </div>
          <div className='tile is-vertical is-5.8 m-6'>
            <article className='tile is-child notification is-primary'>
              <p className='title'>Mail Address and Phone</p>
            </article>
            <p className='box mt-0 has-text-left'>
              <ul>
                <li>CAPSA-MC</li>
                <li>12774 Wisteria Drive, #115</li>
                <li>Germantown, MD 20875</li>
                <li>Phone: (301)686-4237</li>
              </ul>
            </p>
          </div>
        </div>
      </section>
    </div>
  )
}

ContactPageTemplate.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  adult: PropTypes.string,
  adultinfo: PropTypes.array,
  coordinator: PropTypes.string,
  coordinatorinfo: PropTypes.array,
  meta_title: PropTypes.string,
  meta_description: PropTypes.string,
}

export default ContactPageTemplate
